import React from 'react';
import server from '../services/server';
import './Landing/Landing.scss';
import withRouter from "../services/withRouter"
import logo from "../static/logo_purple.svg"

const ErrorMessages = {
	NoFirstName: 'יש להזין שם פרטי يرجى اضافة الاسم للشخصي',
	NoLastName: 'יש להזין שם משפחה يرجى اضافة اسم العائلة',
	NoEmailOrPhone: `יש להזין מספר טלפון תקין או כתובת אימייל תקינה
يرجى اضافة رقم هاتف من صحيح او بريد الكتروني صحيح`,
	InvalidEmail: 'יש להזין כתובת אימייל תקינה يرجى اضافة بريد الكتروني صحيح',
	InvalidPhone: 'יש להזין מספר טלפון תקין يرجى اضافة رقم هاتف من صحيح',
	NoResidency: 'יש להזין יישוב מגורים תקין يرجى اضافة مكان السكنى صحيح',
	general: 'הייתה שגיאה במילוי הטופס, אנא בדקו את הפרטים ונסו שנית'
}

const FieldPatterns = {
	firstName: /^\D{2,}$/,
	lastName: /^\D{2,}$/,
	residency: /^.{2,}$/,
	email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	// email: /^\D{2,}$/,
	phone: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{1,3})?[-. )]*(\d{2,3})[-. ]*(\d{1,4})(?:\s*(\d+))?[-. ]*(\d{2,4})\s*$/,
}

export default (withRouter(class Login extends React.Component {
	constructor(props) {
		super(props);
		const hashProp = this.props.searchParams.get("id");
		this.state = {
			hash: hashProp,
			activity: null,
			success: false
		};
		this.validateAndSendForm = this.validateAndSendForm.bind(this);
	}

	getEvent(hash) {
		return server.get(`./activities/landing-data/${hash}`)
		.then(res => {
			if (!res) return
			this.setState({ activity: res })
		})
	}

	componentDidMount() {
		this.getEvent(this.state.hash)
	}

	validateAndSendForm(e) {
		this.setState({ errorKey: ''});
		if (!this.state.firstName || !FieldPatterns.firstName.test(this.state.firstName)) {
			this.setState({ errorKey: 'NoFirstName'});
			document.getElementById('firstName').focus()
			return
		}
		if (!this.state.lastName || !FieldPatterns.lastName.test(this.state.lastName)) {
			this.setState({ errorKey: 'NoLastName'});
			document.getElementById('lastName').focus()
			return
		}
		if (!this.state.email && !this.state.phone) {
			this.setState({ errorKey: 'NoEmailOrPhone'});
			document.getElementById('phone').focus()
			return
		}
		if (this.state.email && !FieldPatterns.email.test(this.state.email)) {
			this.setState({ errorKey: 'InvalidEmail'});
			document.getElementById('email').focus()
			return
		}
		if (this.state.phone && !FieldPatterns.phone.test(this.state.phone)) {
			this.setState({ errorKey: 'InvalidPhone'});
			document.getElementById('phone').focus()
			return
		}
		if (!this.state.residency || !FieldPatterns.residency.test(this.state.residency)) {
			this.setState({ errorKey: 'NoResidency'});
			document.getElementById('residency').focus()
			return
		}
		
		this.setState({ isLoading: true })

		const formSubmitRequest = new Request(`/api/contacts/landing-page-form`, 
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				hash: this.state.hash,
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				email: this.state.email,
				phone: this.state.phone,
				residency: this.state.residency
			})
		})
		fetch(formSubmitRequest)
		.then(res => res.json())
		.then(async res => {
			if (res.error) throw res;
			if (res.ok === false) throw res;
			
			this.setState({ 
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				residency: '',
				errorKey: '',
				isLoading: false,
				success: true
			});
			document.querySelectorAll('.form input').forEach(elm => elm.value = '')
			setTimeout(() => {
				this.setState({ success: false })
			}, 5000);
		})
		.catch(async err => {
			console.log('caught the error');
			console.log(err);
			
			if (err.errorKey) this.setState({ errorKey: err.errorKey })
			else this.setState({ errorKey: 'general '})
		})
	}

	formInputChange(event, newState) {
		newState.errorMsg = '';
		this.setState(newState);
	}

	formInputKeyDown(event) {
		if (event.keyCode === 13) {
			const parentLabel = event.target.parentElement
			const nextElement = parentLabel.nextElementSibling
			if (nextElement.nodeName === 'LABEL') {
				nextElement.children[0].focus()
			} else if (nextElement.nodeName === 'BUTTON') {
				nextElement.focus()
				this.validateAndSendForm()
			}
		}
	}

	isErrorKey(errorKey, errorKey2) {
		return this.state.errorKey === errorKey || this.state.errorKey2 === errorKey
	}

	putDateInHeadline() {
		const activityIdsWithDateInHeadline = [
			'ecde81f8c921e666c53af66ca1accf5e', // Haifa protest
			'3ed65f73c47b4ecdf8b72d9a9d45d255'  // Tel Aviv Jaffa protest
		]
		return activityIdsWithDateInHeadline.includes(this.state.hash)
	}

	getDateText() {
		const dateObj = new Date()
		const curr_date = String(dateObj.getDate()).padStart(2, '0');
		const curr_month = String(dateObj.getMonth() + 1).padStart(2, '0'); //Months are zero based
		const curr_year = dateObj.getFullYear();
		return `${curr_date}.${curr_month}.${curr_year}`;
	}

	render() {
		if (this.state.activity) {
			return <div className="landing-page">
				<img
					alt={"logo"}
					src={logo}
					className={"logo"}
				/>
				<h1>{ this.state.activity.title }{ this.putDateInHeadline()? ' | '+this.getDateText():null}</h1>
				{/* <p>
					עומדים ביחד היא תנועה שנאבקת למען עתיד בטוח בארץ, למען שיטה שסופרת את כולנו, למען חיים בכבוד, בצדק חברתי ובשלום. כי למרות שאנחנו באים ממקומות שונים, למרות שיש לנו זהויות שונות - האינטרסים שלנו משותפים. זה נכון לכולנו, ערבים ויהודים, וזה הופך אותנו לרוב. בעומדים ביחד אנחנו מתארגנים, בונים כוח, ובונים תנועה שתוביל את הדרך לשם. הצטרפו אלינו.
				</p>
				<p>
					نقف معًا هو حراك يناضل من أجل مستقبل آمن في البلاد، من أجل نظام يهمنا جميعًا، من أجل حياة كريمة وعدالة اجتماعية وسلام. لأنه على الرغم من أننا نأتي من أماكن مختلفة، على الرغم من اختلاف هوياتنا - فإن اهتماماتنا مشتركة. هذا صحيح بالنسبة لنا جميعًا كعرب ويهود، وهو يجعلنا أغلبية. في نقف معًا، نتنظم  ونبني القوة ونبني حراكًا سيقود الطريق إلى هناك. انضموا إلينا.
				</p> */}
				<div className="form">
					<label htmlFor="firstName">الاسم الشخصي שם פרטי *
						<input type="text" id="firstName" name="firstName" 
							   className={ this.isErrorKey('NoFirstName')?'error':''} 
							   onChange={e => this.formInputChange(e, {firstName: e.target.value.trim()})}
							   onKeyDown={e => this.formInputKeyDown(e)}>
						</input>
					</label>

					<label htmlFor="lastName">اسم العائلة שם משפחה *
						<input type="text" id="lastName" name="lastName" 
							   className={ this.isErrorKey('NoLastName')?'error':''} 
						       onChange={e => this.formInputChange(e, {lastName: e.target.value.trim()})}
						       onKeyDown={e => this.formInputKeyDown(e)}>
						</input>
					</label>

					<label htmlFor="phone">رقم الهاتف טלפון { !this.state.email? '*' : null }
						<input type="tel" id="phone" name="phone" 
							   className={ this.isErrorKey('NoEmailOrPhone', 'InvalidPhone')?'error':''} 
						       onChange={e => this.formInputChange(e, {phone: e.target.value.trim()})}
						       onKeyDown={e => this.formInputKeyDown(e)}>
						</input>
					</label>

					<label htmlFor="email">البريد الإلكتروني כתובת דוא"ל { !this.state.phone? '*' : null }
						<input type="email" id="email" name="email" land="en"
							   className={ this.isErrorKey('NoEmailOrPhone', 'InvalidEmail')?'error':''} 
						       onChange={e => this.formInputChange(e, {email: e.target.value.trim()})}
						       onKeyDown={e => this.formInputKeyDown(e)}>
						</input>
					</label>

					<label htmlFor="residency">مكان الاقامة יישוב מגורים *
						<input type="text" id="residency" name="residency" 
						       onChange={e => this.formInputChange(e, {residency: e.target.value.trim()})}
						       onKeyDown={e => this.formInputKeyDown(e)}>
						</input>
					</label>

					<button onClick={this.validateAndSendForm}>
						{ this.state.isLoading? 
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
									<radialGradient id="a" cx=".7" cy=".3" fx=".7" fy=".3" gradientTransform="scale(2)">
										<stop offset="0" stopColor="#FFCF00"/>
										<stop offset=".3" stopColor="#FFCF00" stopOpacity=".9"/>
										<stop offset=".6" stopColor="#FFCF00" stopOpacity=".6"/>
										<stop offset=".8" stopColor="#FFCF00" stopOpacity=".3"/>
										<stop offset="1" stopColor="#FFCF00" stopOpacity="0"/>
									</radialGradient>
									<circle cx="100" cy="100" r="70" fill="none" stroke="url(#a)" strokeDasharray="200 1000" strokeLinecap="round" strokeWidth="15" transform-origin="center">
										<animateTransform attributeName="transform" calcMode="spline" dur="2" keySplines="0 0 1 1" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="360;0"/>
									</circle>
									<circle cx="100" cy="100" r="70" fill="none" stroke="#FFCF00" strokeLinecap="round" strokeWidth="15" opacity=".2" transform-origin="center"/>
								</svg>
						: 'שליחה ارسال'}
						</button>
				</div>
				<div className="error-msg">
					{ this.state.errorKey? ErrorMessages[this.state.errorKey]:'' }
				</div>
				{ (this.state.success)? <div className='success-msg'>
					תודה! הטופס נשלח בהצלחה
				</div>:null}
				<p className="disclaimer">
הפרטים שלך ישמרו חסויים ומאובטחים. בשליחת הפרטים שלך את.ה מסכימ.ה לקבל מ״עומדים ביחד״ ומהתארגנויות שחולקות את סולם הערכים שלנו עדכונים, בהתאם לתחומי העניין שלך, ומאשר שאתה יודע שבמקרים מסוימים, ורק במידה ויהיה בכך צורך, פרטיך יועברו לקבלני משנה שלנו שמבצעים פעילות עבורנו (כמו למשל חברת סליקה).

ستُحفظ معلوماتك بسرية وأمان. بإرسال بياناتك، فإنك توافق على تلقي حتلنات من "نقف معًا" ومنظمات تشاركنا نفس القيم، وذلك وفقًا لاهتماماتك. كما ويعني هذا موافقتك بأنه في بعض الحالات، وعند الضرورة فقط، قد تُنقل معلوماتك إلى متعاقدين فرعيين يعملون لصالحنا (مثل شركة معالجة المدفوعات).

				</p>
			</div>
		} else {
			return <div className="landing-page loading">
				Loading...
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
					<radialGradient id="a" cx=".7" cy=".3" fx=".7" fy=".3" gradientTransform="scale(2)">
						<stop offset="0" stopColor="#FFCF00"/>
						<stop offset=".3" stopColor="#FFCF00" stopOpacity=".9"/>
						<stop offset=".6" stopColor="#FFCF00" stopOpacity=".6"/>
						<stop offset=".8" stopColor="#FFCF00" stopOpacity=".3"/>
						<stop offset="1" stopColor="#FFCF00" stopOpacity="0"/>
					</radialGradient>
					<circle cx="100" cy="100" r="70" fill="none" stroke="url(#a)" strokeDasharray="200 1000" strokeLinecap="round" strokeWidth="15" transform-origin="center">
						<animateTransform attributeName="transform" calcMode="spline" dur="2" keySplines="0 0 1 1" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="360;0"/>
					</circle>
					<circle cx="100" cy="100" r="70" fill="none" stroke="#FFCF00" strokeLinecap="round" strokeWidth="15" opacity=".2" transform-origin="center"/>
				</svg>
			</div>
		}
	}
}))

